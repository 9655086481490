<template>
  <div>
     <new-branch-modal
      :showCreateAccount="showCreateAccount"
      :ownerId="idEnterprise"
      v-if="idEnterprise"
      v-on:close="closeAccountModal"
      v-on:error="handleError"
      v-on:branch-created="branchCreated"
      :itemId="idEnterprise"
      :clean="clean"
      :type="type"
      v-on:cleaned="clean = false"
      v-on:Account-updated="accountUpdated"
    ></new-branch-modal>

      <new-security-modal
      :showSecurityModal="showSecurityModal"
      :ownerId="ownerIDI"
      v-on:close="closeSecurityModal"
      v-on:error="handleError"
      v-on:security-updated="closeSecurityModal"
    ></new-security-modal>

    <base-header type="gradient-primary" class="pb-6 pb-8 pt-5 pt-md-8">
      <card-stats v-if="isUserAccount" :period="period"></card-stats>
    </base-header>
    <div class="container-fluid mt--7">
      <div class="row mt-5">
        <div class="col-xl-12">
          <branches-table-v-2
            :idEnterprise="idEnterprise"
            :updateTable="updateTable"
            v-on:table-loaded="tableLoaded"
            v-on:security-item="prepareSecurityItem"
            v-on:new-item="newItem"
            v-on:error="handleError"
            v-on:update-item="prepareUpdateItem"
            :type="type">
          </branches-table-v-2>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import CardStats from './Dashboard/CardStats';
import periodSearchMixin from '../mixins/periodSearchMixin';  
import enums from '../enums/enums';
import BranchesTableV2 from './Tables/BranchesTableV2.vue';
import handleErrorMixin from '../mixins/handleErrorMixin';
import NewBranchModal from './Modals/NewBranchModalV2.vue';
import NewSecurityModal from './Modals/SecurityModal.vue';


import enumRoles from '../enums/enumRoles';

export default {
    components: {
      CardStats,
      BranchesTableV2,
      NewBranchModal,
      NewSecurityModal
    },
    mixins: [handleErrorMixin, periodSearchMixin],
    data() {
      return {
        showCreateAccount: false,
        showSecurityModal: false,
        branchId: 0,
        idEnterprise: -1,
        ownerIDI: -1,
        type: enums.BRANCH,
        clean: Boolean,
        period:{},
        updateTable: false
      };
    },
    computed: {
      isUserAccount(){
        return this.$store.getters.jwtRole === enumRoles.ACCOUNT
      }
    },
    methods: {
      closeAccountModal() {
        this.showCreateAccount = false;
        this.branchId = 0;
      },
      handleError(error) {
        this.$emit('error', error)
      },
      branchCreated() {
        this.updateTable = true
        this.clean = true
        this.showCreateAccount = false
      },
      tableLoaded(){
        this.updateTable = false
      },
      accountUpdated() {
        this.branchId = 0;
        this.showCreateAccount = false;
      },
      newItem() {
        this.showCreateAccount = true;
      },
      securityMod() {
        this.showSecurityModal = true;
        this.$toasted.show('security moodal open');
      },
      closeSecurityModal() {
        this.showSecurityModal = false;
        this.branchId = 0;
      },
      prepareUpdateItem(row) {
        this.branchId = row.id;
        this.showCreateAccount = true;
      },
      prepareSecurityItem(row) {
        this.ownerIDI = row;
        this.showSecurityModal = true;
      },
    },
    mounted() {
      this.initializePeriod();
      if(this.$route.params.id){
        this.idEnterprise = Number(this.$route.params.id);
      }
    }
  };
</script>
<style></style>
