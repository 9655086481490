<template>
  <div></div>
</template>
<script>
  import {AUTH_LOGOUT} from '../store/actions/auth';
  export default {
    data (){
      return {
      }
    },
    created:function(){
      this.$store.dispatch(AUTH_LOGOUT);
      this.$router.push('/login');
    }
  }
</script>