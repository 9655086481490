var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("base-header", {
        staticClass: "pb-6 pb-8 pt-5 pt-md-8",
        attrs: { type: "gradient-primary" }
      }),
      _c("div", { staticClass: "container-fluid mt--7" }, [
        _c("div", { staticClass: "row mt-5" }, [
          _c(
            "div",
            { staticClass: "col-xl-12" },
            [
              _c(
                "card",
                { attrs: { shadow: "" } },
                [
                  _c("div", [
                    _c("h3", { staticClass: "mb-0", attrs: { h3: "" } }, [
                      _vm._v(
                        "\n                Sesión: " +
                          _vm._s(_vm.idSession) +
                          "\n              "
                      )
                    ]),
                    _vm.idDevice
                      ? _c("div", [
                          _c("h3", { staticClass: "mb-0", attrs: { h3: "" } }, [
                            _c("span", [
                              _vm._v(
                                " Dispositivo: " + _vm._s(_vm.device.deviceName)
                              )
                            ])
                          ]),
                          _c("span", { class: _vm.detailClass }, [
                            _vm._v(
                              _vm._s("Descripción: ") +
                                _vm._s(_vm.device.deviceDescription)
                            )
                          ]),
                          _vm._v("\n                 \n                "),
                          _c("span", { class: _vm.detailClass }, [
                            _vm._v(_vm._s("Email: ") + _vm._s(_vm.device.email))
                          ])
                        ])
                      : _vm._e(),
                    _vm.idBranch
                      ? _c("div", [
                          _c("h3", { staticClass: "mb-0", attrs: { h3: "" } }, [
                            _c("span", [
                              _vm._v(" Sucursal: " + _vm._s(_vm.branch.name))
                            ])
                          ]),
                          _c("span", { class: _vm.detailClass }, [
                            _vm._v(
                              _vm._s("Clave interna: ") +
                                _vm._s(_vm.branch.internalKey)
                            )
                          ]),
                          _c("tr"),
                          _c("span", { class: _vm.detailClass }, [
                            _vm._v(
                              _vm._s("Email: ") + _vm._s(_vm.branch.contactMail)
                            )
                          ])
                        ])
                      : _vm._e()
                  ]),
                  _c("transactions-table-v-2", {
                    attrs: {
                      idBranch: _vm.idBranch,
                      idDevice: _vm.idDevice,
                      idSession: _vm.idSession
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }