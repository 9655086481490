<template>
  <div>
    <new-enterprise-modal
      :showCreateAccount="showCreateAccount"
      :ownerId="idAccount"
      v-on:close="closeEnterpriseModal"
      v-on:error="handleError"
      v-on:enterprise-created="updateNewAccount"
      :itemId="enterpriseId"
      :clean="clean"
      :type="type"
      v-on:cleaned="clean = false"
      v-on:Account-updated="accountUpdated"
    ></new-enterprise-modal>
    <base-header type="gradient-primary" class="pb-6 pb-8 pt-5 pt-md-8">
      <card-stats v-if="isUserAccount" :period="period"></card-stats>
    </base-header>
    <div class="container-fluid mt--7">
      <div class="row mt-5">
        <div class="col-xl-12">
          <enterprises-table
            :idAccount="idAccount"
            :updateTable="updteTable"
            v-on:table-loaded="tableLoaded"
            v-on:new-item="newItem"
            v-on:error="handleError"
            v-on:update-item="prepareUpdateItem"
            :type="type">
          </enterprises-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import CardStats from './Dashboard/CardStats';
import handleErrorMixin from '../mixins/handleErrorMixin';
import periodSearchMixin from '../mixins/periodSearchMixin';  
import enums from '../enums/enums';
import NewEnterpriseModal from './Modals/NewEnterpriseModalV3.vue';
import EnterprisesTable from './Tables/EnterprisesTable.vue';
import { isEmptyOrNull } from '../utils/utilMethods';
import enumRoles from '../enums/enumRoles';
  export default {
    components: {
      CardStats,
      NewEnterpriseModal,
      EnterprisesTable,
    },
    mixins: [handleErrorMixin, periodSearchMixin],
    data() {
      return {
        showCreateAccount: false,
        enterpriseId: 0,
        idAccount: -1,
        type: enums.ENTERPRISE,
        clean: Boolean,
        period:{},
        updteTable: false
      };
    },
    computed: {
      isUserAccount(){
        return this.$store.getters.jwtRole === enumRoles.ACCOUNT
      }
    },
    methods: {
      closeEnterpriseModal() {
        this.showCreateAccount = false;
        this.enterpriseId = 0;
      },
      handleError(error) {
        this.$emit('error', error)
      },
      updateNewAccount() {
        this.clean = true
        this.updteTable = true
        this.showCreateAccount = false;
      },
      tableLoaded(){
        this.updteTable = false
      },
      accountUpdated() {
        this.enterpriseId = 0;
        this.showCreateAccount = false;
      },
      newItem() {
        this.showCreateAccount = true;
      },
      prepareUpdateItem(row) {
        this.enterpriseId = row.id;
        this.showCreateAccount = true;
      },
    },
    mounted() {
      this.initializePeriod();
      if(isEmptyOrNull(this.$route.params.id)) {
        this.idAccount = 0
      }else{
        this.idAccount = Number(this.$route.params.id)
      }
    }
  };
</script>
<style></style>
