var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("send-mail-modal", {
        attrs: { showSendMailModal: _vm.showSendMailModal },
        on: {
          close: function($event) {
            _vm.showSendMailModal = false
          },
          error: _vm.handleMailSendError
        }
      }),
      _c("base-header", {
        staticClass: "pb-6 pb-8 pt-5 pt-md-8",
        attrs: { type: "gradient-primary" }
      }),
      _c("div", { staticClass: "container-fluid mt--7" }, [
        _c("div", { staticClass: "row mt-5 justify-content-center" }, [
          _c(
            "div",
            { staticClass: "col-xl-4" },
            [
              _c("product-card", {
                attrs: {
                  icon: "ni ni-email-83",
                  title: "Correo Electrónico",
                  description:
                    "Nuestro equipo de soporte te respondera a la brevedad, nuestros horarios de atencion son de 8:00 a 20:00 de lunes a viernes",
                  button: "Enviar correo"
                },
                nativeOn: {
                  click: function($event) {
                    return _vm.showEmailModal()
                  }
                }
              })
            ],
            1
          ),
          _c("div", { staticClass: "col-xl-1" }),
          _c(
            "div",
            { staticClass: "col-xl-4" },
            [
              _c("product-card", {
                attrs: {
                  icon: "ni ni-email-83",
                  title: "Mesa de Ayuda",
                  description:
                    "Estamos Listos para atenderte y resolver todas tus preguntas. No dudes en contactarnos por WhatsApp.",
                  button: "811 306 0714"
                },
                nativeOn: {
                  click: function($event) {
                    return _vm.goWhatsAppWeb()
                  }
                }
              })
            ],
            1
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }