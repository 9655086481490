<!-- https://codepen.io/kresogalic/pen/dGzvRV -->
<template>
  <div>
    <send-mail-modal
      :showSendMailModal="showSendMailModal"
      v-on:close="showSendMailModal=false"
      v-on:error="handleMailSendError"
      :support="false"
    />
    <base-header type="gradient-primary" class="pb-6 pb-8 pt-5 pt-md-8"></base-header>
    <div class="container-fluid mt--7">
      <div class="row mt-5 justify-content-center">
        <div class="col-xl margin-for-col">
          <product-card
            icon="ni ni-mobile-button"
            title="Zuma Link"
            description="Contamos con una liga de pagos que es la manera más práctica y segura de obtener tus pagos sin necesidad de tener la tarjeta presente. Revoluciona el pago sin presencia de tarjeta SIN CONTRA CARGOS."
            button="Generar Liga de Pago"
            @click.native="redirectZumaLink()"
          />
        </div>
        <div class="col-xl margin-for-col">
          <product-card
            icon="ni ni-money-coins"
            title="QPOS"
            description="Si cuentas con un Smartphone o Tablet, puedes hacer uso del lector de Zuma Pagos, sin usar tus datos móviles solamente con el Bluetooth de tus Smartphone."
            button="Solicitar lector"
            @click.native="showEmailModal()"
          />
        </div>
        <div class="col-xl margin-for-col">
          <product-card
            icon="ni ni-credit-card"
            title="Servicios"
            description="Visita nuestra página para consultar servicios adicionales."
            button="Ir"
            @click.native="goToZumapagosPage()"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ProductCard from "./Cards/ProductCard";
import SendMailModal from "./Modals/SendMailModal";

export default {
  components: {
    ProductCard,
    SendMailModal
  },
  data() {
    return {
      showSendMailModal: false
    };
  },
  methods: {
    redirectZumaLink() {
      this.$router.push({name: 'link-generator'}) 
    },
    showEmailModal() {
      this.showSendMailModal = true;
    },
    goToZumapagosPage() {
      window.open("https://zumapagos.com/#mss", "_blank");
    },
    handleMailSendError(error) {
      this.showSendMailModal = false;
      this.$emit("error", error);
    }
  }
};
</script>