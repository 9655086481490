var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("new-device-modal", {
        attrs: {
          showCreateDevice: _vm.showCreateDevice,
          idDevice: _vm.idDevice,
          clean: _vm.clean,
          type: _vm.type,
          branch: _vm.branch
        },
        on: {
          close: _vm.closeDeviceModal,
          error: _vm.handleError,
          "device-created": _vm.newDevice,
          cleaned: function($event) {
            _vm.clean = false
          },
          "device-updated": _vm.deviceUpdated
        }
      }),
      _c(
        "base-header",
        {
          staticClass: "pb-6 pb-8 pt-5 pt-md-8",
          attrs: { type: "gradient-primary" }
        },
        [
          _vm.isUserAccount
            ? _c("card-stats", { attrs: { period: _vm.period } })
            : _vm._e()
        ],
        1
      ),
      _c("div", { staticClass: "container-fluid mt--7" }, [
        _c("div", { staticClass: "row mt-5" }, [
          _c(
            "div",
            { staticClass: "col-xl-12" },
            [
              _c("devices-table", {
                attrs: {
                  idBranch: _vm.idBranch,
                  updateTable: _vm.updateTable,
                  deviceUpdated: _vm.deviceUpdateds,
                  "vv-on:table-updated": "tableUpdated",
                  type: _vm.type
                },
                on: {
                  "new-item": _vm.newItem,
                  error: _vm.handleError,
                  "update-item": _vm.prepareUpdateItem,
                  "update-table-completed": _vm.updatedTableCompleated
                }
              })
            ],
            1
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }