<template>
  <div>
    <new-device-modal
      :showCreateDevice="showCreateDevice"
      :idDevice="idDevice"
      :clean="clean"
      :type="type"
      :branch="branch"
      v-on:close="closeDeviceModal"
      v-on:error="handleError"
      v-on:device-created="newDevice"
      v-on:cleaned="clean = false"
      v-on:device-updated="deviceUpdated"
    ></new-device-modal>
    <base-header type="gradient-primary" class="pb-6 pb-8 pt-5 pt-md-8">
      <card-stats v-if="isUserAccount" :period=period></card-stats>
    </base-header>
    <div class="container-fluid mt--7">
      <div class="row mt-5">
        <div class="col-xl-12">
           <devices-table
            :idBranch="idBranch"
            :updateTable="updateTable"
            :deviceUpdated="deviceUpdateds"
            vv-on:table-updated="tableUpdated"
            v-on:new-item="newItem"
            v-on:error="handleError"
            v-on:update-item="prepareUpdateItem"
            v-on:update-table-completed="updatedTableCompleated"
            :type="type">
          </devices-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import CardStats from './Dashboard/CardStats';
import handleErrorMixin from '../mixins/handleErrorMixin';
import periodSearchMixin from '../mixins/periodSearchMixin';  
import enums from '../enums/enums';
import DevicesTable from './Tables/DevicesTableV3.vue';
import NewDeviceModal from './Modals/NewDeviceModal.vue';
import { isEmptyOrNull } from '../utils/utilMethods';
import enumRoles from '../enums/enumRoles';

  export default {
    components: {
      CardStats,
      DevicesTable,
      NewDeviceModal
    },
    mixins: [handleErrorMixin, periodSearchMixin],
    data() {
      return {
        showCreateDevice: false,
        idDevice: 0,
        deviceUpdateds: {},
        idBranch: 0,
        type: enums.BRANCH,
        clean: Boolean,
        period:{},
        updateTable: false,
        branch:{}
      };
    },
    computed: {
      isUserAccount(){
        return this.$store.getters.jwtRole === enumRoles.ACCOUNT
      }
    },
    methods: {
      closeDeviceModal() {
        this.clean = this.idDevice > 0
        this.showCreateDevice = false;
        this.idDevice = 0;
        this.branch = {}
      },
      handleError(error) {
        this.$emit('error', error)
      },
      newDevice() {
        this.clean = true
        this.updateTable = true
        this.showCreateDevice = false;
      },
      updatedTableCompleated(){
        this.clean = true
      },
      deviceUpdated(event) {
        this.clean = true
        this.deviceUpdateds = event;
        this.idDevice = 0
        this.showCreateDevice = false;
      },
      tableUpdated(){
        this.updateTable = false
      },
      newItem(branch) {
        // console.log(branch);
        this.branch = branch
        this.showCreateDevice = true;
      },
      prepareUpdateItem(row) {
        this.idDevice = row.idUser;
        this.showCreateDevice = true;
      },
    },
    mounted() {
      if (isEmptyOrNull(this.$route.params.id)) {
        this.idBranch = 0
      }else{
       this.idBranch = Number(this.$route.params.id)
      }
      this.initializePeriod();
    }
  };
</script>
<style></style>
