var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("new-client-modal", {
        attrs: {
          showCreateClient: _vm.showCreateClient,
          idUser: _vm.idUser,
          clean: _vm.clean,
          type: _vm.type
        },
        on: {
          close: _vm.closeClientModal,
          error: _vm.handleError,
          "client-created": _vm.newClient,
          cleaned: function($event) {
            _vm.clean = false
          },
          "client-updated": _vm.clientUpdated
        }
      }),
      _c("base-header", {
        staticClass: "pb-6 pb-8 pt-5 pt-md-8",
        attrs: { type: "gradient-primary" }
      }),
      _c("div", { staticClass: "container-fluid mt--7" }, [
        _c("div", { staticClass: "row mt-5" }, [
          _c(
            "div",
            { staticClass: "col-xl-12" },
            [
              _c("clients-account-table", {
                attrs: {
                  title: "Vendedores",
                  type: _vm.type,
                  clientUp: _vm.clientUp,
                  updateTable: _vm.updateTable
                },
                on: {
                  error: _vm.handleError,
                  "new-client": function($event) {
                    _vm.showCreateClient = true
                  },
                  "update-client": _vm.prepareUpdateClient,
                  "update-table-completed": _vm.tableUpdated
                }
              })
            ],
            1
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }