<template>
  <modal :bigModal="true" :show="showSecurityModal"  :showClose="true" v-on:close="$emit('close')">
    <div slot="header">
      <div class="container-fluid">
        <div class="row">Seguridad</div>
      </div>
    </div>
    <card shadow type="secondary">
        <b-tabs pills card no-nav-style v-model="tabIndex" no-key-nav class="local-card">
            <b-tab title-item-class="flex-fill text-center"  title="Seguridad" >
                      <span slot="title">Seguridad</span>
                      <b-container>
                        <b-row class="mt-6 ">
                          <b-col>
                             <b-form-checkbox
                             style="margin-top:35px;"
                              v-model="item.pinRequired"
                              value="Y"
                              unchecked-value="N"
                            >
                              Seguridad
                            </b-form-checkbox>
                            <base-input
                              input-classes="form-control-alternative"
                              :error = "validateField('pinSecurity')"
                              label="PIN"
                              v-model="item.pin"
                              :capitalize='true'
                              :maxlength="4"
                              validation="number"
                              :placeholder="placeHold"
                            ></base-input>
                            {{this.pinInfo}}
                          </b-col>
                           <b-col>
                           &nbsp;
                          </b-col>
                           <b-col>
                            <!-- Action list  Notempty-->
                            <b-form-group label="Acciones de seguridad:" v-if="item.actionsList.length > 0 && showEmptyActions == false">
                                <b-form-checkbox v-for="n in item.actionsList"  v-model="n.status" :key="n.actionId" value="A" unchecked-value="I">{{ n.description }} </b-form-checkbox>
                            </b-form-group>
                            <!-- Action list empty-->
                            <b-form-group label="Acciones de seguridad"  v-if=" showEmptyActions == true">
                              <b-form-checkbox-group
                                label="Acciones de seguridad:"
                                v-model="item.actionsList"
                                :options="optionstwo"
                                class="mb-3"
                                value-field="item"
                                text-field="name"
                                disabled-field="notEnabled"
                              ></b-form-checkbox-group>
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </b-container>
                    </b-tab>



        </b-tabs>
      <div slot="footer" class="bg-white border-0">
        <div class="row align-items-center">
          <div class="col-12 text-right">
            <!-- <base-button type="primary" class="my-4 ml-3 mr-3"  @click="goPreviousPage" v-if="tabIndex > 0" >Atras</base-button> -->
            <base-button type="primary" class="my-4 ml-3 mr-3"  @click="goNextPage" >{{textButton}}</base-button> 
          </div>
        </div>
      </div>
    </card>
  </modal>
</template>
<script>
flatpickr.localize(Spanish);
import "flatpickr/dist/flatpickr.css";
import flatpickr from "flatpickr";
import Spanish from "flatpickr/dist/l10n/es.js";
import axiosResponseMixin, { processErrorResponse } from '../../mixins/axiosResponseMixin';
import { getActionsList  } from '../../utils/api';
import { saveSecurityBranch} from '../../utils/api';
import { catalogs } from '../../utils/catalog';
import enums from '../../enums/enums';

export default {
  name: "new-security-modal",
  components: {
  },
  mixins: {axiosResponseMixin},
  props: {
    ownerId: Number,
    showSecurityModal: {
      type: Boolean
    },
    itemId: {},
    clean: {}
  },
  data() {
    return {
      jsonData: {
        
      },
      type: enums.BRANCH,
      item: {
        idHierarchy:'',
        pinRequired:'N',
        pin:'',
        actionsList:[],
       

      },
      emptyItem: {
        idHierarchy:'',
        pinRequired:'N',
        pin:'',
        actionsList:[],
       
      },
      textButton: 'Guardar',
      catalogs: catalogs,
      tabIndex: 0,
      numberTabs: 1,
      searchTerm: '',
      showErrorMessages: false,
      showAssignItem: false,
      selectedItems:{},
      enterpriseName: '',
      enterpriseSelectedId: 0,
      showDialogAutoCompleate: false,
      hideAssignEnterprise:true,

      pinNumber:16542,
      passwordFieldType:'password',
      herence:'',
      actionsSecurity:[],
      optionstwo:[],
      showEmptyActions:true,
      securityTemp:"",
      pinInfo:"",
      placeHold:"",
    };
  },
  methods: {
    handleError(error) {
      this.$emit('error', error)
    },
    itemsSelected(event) {
      this.enterpriseName = event[0].fullName;
      this.item.idEnterprise = event[0].id
      this.showAssignItem = false
      this.enterpriseSelectedId = this.item.idEnterprise
      this.showDialogAutoCompleate = true
    },
    // Go post security
    goNextPage() {
      this.showErrorMessages = true                
      if (!this.canContinue()) {
        this.showErrorMessages = true
        return
      } else{
        this.showErrorMessages = false
        this.item.idHierarchy = this.ownerId ;
        this.saveSecurityConfig();
      }
    },
    canContinue() {
      for (let i = 0; i < 1; i++) {
        if (this.validateField(this.item.pin) !== '')
          return false;
      }
      return true;
    },
    validateField(key) {
      if (this.showErrorMessages) {
        if (this.tabIndex === 0) {
            return this.item.pinRequired === 'Y' &&  (this.item.pin === '' || this.item.pin.length != 4 ) ? 'Ingrese un PIN de 4 digitos' : '';
        }
        return '';
      }            
    },
 
    isEmptyOrNull(data) {
      return data === '' || data === null
    },
    cleanData() {
      this.item = this.emptyItem
      this.tabIndex = 0
    },
    saveSecurityConfig() {
      let loaded = this.$loading.show({loader: 'dots'});
      let dto = this.item;
      saveSecurityBranch(dto)
      .then(response => {
        loaded.hide()
        if (response.data.statusCode === 200) {
          this.$toasted.show('Configuración de seguridad de la sucursal guardada correctamente');
          this.cleanData();
          this.$emit('security-updated');
        }else{
          processErrorResponse(response.data, this.$toasted)
        }

      })
      .catch(error => {
       loaded.hide()
       this.$emit('error', error)
      });
      
    },
    getActionsList() {
      getActionsList()
      .then(response => { 
        if (response.data){
            this.actionsSecurity = response.data.result;
            this.opcionestwo = this.actionsSecurity;
            for (let i = 0; i < this.opcionestwo.length; i++) {
              this.optionstwo.push({"item": {"actionId" : this.opcionestwo[i].actionId, "status":"A"},"name": this.opcionestwo[i].description});
            }
        }else{
          processErrorResponse(response.data, this.$toasted);
        }
      })
      .catch(error => {
        this.$emit('error', error)
      })
    },

    
     

  },
  watch: {
    'jsonData': function () {
			let c = this.formatJson(JSON.stringify(this.jsonData))
			this.drawResCode(c)
		},
    clean: {
      handler(newValue) {
        if (newValue) {
          this.cleanData();
          this.$emit("cleaned");
        }
      }
    }
  },
  mounted() {
    if (isNaN(this.item.idEnterprise))
      this.item.idEnterprise = undefined
      
    this.getActionsList();
  },
};
</script>
<style >
.disabledTab {
  pointer-events: none;
}

.local-card .card-header {
  height: 0px;
  visibility: hidden;
  padding: 0px;
}

.body {
  font-size: 14px;
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.clearfix {
  *zoom: 1;
}

.clearfix:before,
.clearfix:after {
  content: '';
  display: table;
}

.clearfix:after {
  clear: both;
}

.t {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 60px;
}

.editor-w {
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 20px;
}

.w-2 {
  float: left;
  width: 100%;
}

.editor {
  padding: 20px 60px;
}

.pure-form select{
  min-height: 2.75em;
}

/* .big-modal-dialog {
  max-width: 90% ;
} */
</style>