var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "card shadow",
      class: _vm.type === "dark" ? "bg-default" : ""
    },
    [
      _c(
        "div",
        {
          staticClass: "card-header border-0",
          class: _vm.type === "dark" ? "bg-transparent" : ""
        },
        [
          _c("div", { staticClass: "row align-items-center" }, [
            _c("div", { staticClass: "col" }, [
              _c(
                "h3",
                {
                  staticClass: "mb-0",
                  class: _vm.type === "dark" ? "text-white" : ""
                },
                [_vm._v("\n          " + _vm._s(_vm.title) + "\n        ")]
              )
            ]),
            _c(
              "div",
              { staticClass: "col text-right" },
              [
                _c("base-button", { attrs: { type: "primary", size: "sm" } }, [
                  _vm._v("See all")
                ])
              ],
              1
            )
          ])
        ]
      ),
      _c(
        "div",
        { staticClass: "table-responsive" },
        [
          _c(
            "base-table",
            {
              staticClass: "table align-items-center table-flush",
              class: _vm.type === "dark" ? "table-dark" : "",
              attrs: {
                "thead-classes":
                  _vm.type === "dark" ? "thead-dark" : "thead-light",
                "tbody-classes": "list",
                data: _vm.tableData
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(ref) {
                    var row = ref.row
                    return [
                      _c("th", { attrs: { scope: "row" } }, [
                        _c("div", { staticClass: "media align-items-center" }, [
                          _c(
                            "a",
                            {
                              staticClass: "avatar rounded-circle mr-3",
                              attrs: { href: "#" }
                            },
                            [
                              _c("img", {
                                attrs: {
                                  alt: "Image placeholder",
                                  src: row.img
                                }
                              })
                            ]
                          ),
                          _c("div", { staticClass: "media-body" }, [
                            _c("span", { staticClass: "name mb-0 text-sm" }, [
                              _vm._v(_vm._s(row.title))
                            ])
                          ])
                        ])
                      ]),
                      _c("td", { staticClass: "budget" }, [
                        _vm._v(
                          "\n          " + _vm._s(row.budget) + "\n        "
                        )
                      ]),
                      _c(
                        "td",
                        [
                          _c(
                            "badge",
                            {
                              staticClass: "badge-dot mr-4",
                              attrs: { type: row.statusType }
                            },
                            [
                              _c("i", { class: "bg-" + row.statusType }),
                              _c("span", { staticClass: "status" }, [
                                _vm._v(_vm._s(row.status))
                              ])
                            ]
                          )
                        ],
                        1
                      ),
                      _c("td", [
                        _c("div", { staticClass: "avatar-group" }, [
                          _c(
                            "a",
                            {
                              staticClass: "avatar avatar-sm rounded-circle",
                              attrs: {
                                href: "#",
                                "data-toggle": "tooltip",
                                "data-original-title": "Ryan Tompson"
                              }
                            },
                            [
                              _c("img", {
                                attrs: {
                                  alt: "Image placeholder",
                                  src: "img/theme/team-1-800x800.jpg"
                                }
                              })
                            ]
                          ),
                          _c(
                            "a",
                            {
                              staticClass: "avatar avatar-sm rounded-circle",
                              attrs: {
                                href: "#",
                                "data-toggle": "tooltip",
                                "data-original-title": "Romina Hadid"
                              }
                            },
                            [
                              _c("img", {
                                attrs: {
                                  alt: "Image placeholder",
                                  src: "img/theme/team-2-800x800.jpg"
                                }
                              })
                            ]
                          ),
                          _c(
                            "a",
                            {
                              staticClass: "avatar avatar-sm rounded-circle",
                              attrs: {
                                href: "#",
                                "data-toggle": "tooltip",
                                "data-original-title": "Alexander Smith"
                              }
                            },
                            [
                              _c("img", {
                                attrs: {
                                  alt: "Image placeholder",
                                  src: "img/theme/team-3-800x800.jpg"
                                }
                              })
                            ]
                          ),
                          _c(
                            "a",
                            {
                              staticClass: "avatar avatar-sm rounded-circle",
                              attrs: {
                                href: "#",
                                "data-toggle": "tooltip",
                                "data-original-title": "Jessica Doe"
                              }
                            },
                            [
                              _c("img", {
                                attrs: {
                                  alt: "Image placeholder",
                                  src: "img/theme/team-4-800x800.jpg"
                                }
                              })
                            ]
                          )
                        ])
                      ]),
                      _c("td", [
                        _c(
                          "div",
                          { staticClass: "d-flex align-items-center" },
                          [
                            _c("span", { staticClass: "completion mr-2" }, [
                              _vm._v(_vm._s(row.completion) + "%")
                            ]),
                            _c(
                              "div",
                              [
                                _c("base-progress", {
                                  staticClass: "pt-0",
                                  attrs: {
                                    type: row.statusType,
                                    "show-percentage": false,
                                    value: row.completion
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        )
                      ]),
                      _c(
                        "td",
                        { staticClass: "text-right" },
                        [
                          _c(
                            "base-dropdown",
                            {
                              staticClass: "dropdown",
                              attrs: { position: "right" }
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "btn btn-sm btn-icon-only text-light",
                                  attrs: {
                                    slot: "title",
                                    role: "button",
                                    "data-toggle": "dropdown",
                                    "aria-haspopup": "true",
                                    "aria-expanded": "false"
                                  },
                                  slot: "title"
                                },
                                [_c("i", { staticClass: "fas fa-ellipsis-v" })]
                              ),
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass: "dropdown-item",
                                    attrs: { href: "#" }
                                  },
                                  [_vm._v("Action")]
                                ),
                                _c(
                                  "a",
                                  {
                                    staticClass: "dropdown-item",
                                    attrs: { href: "#" }
                                  },
                                  [_vm._v("Another action")]
                                ),
                                _c(
                                  "a",
                                  {
                                    staticClass: "dropdown-item",
                                    attrs: { href: "#" }
                                  },
                                  [_vm._v("Something else here")]
                                )
                              ]
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [
              _c("template", { slot: "columns" }, [
                _c("th", [_vm._v("Project")]),
                _c("th", [_vm._v("Budget")]),
                _c("th", [_vm._v("Status")]),
                _c("th", [_vm._v("Users")]),
                _c("th", [_vm._v("Completion")]),
                _c("th")
              ])
            ],
            2
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "card-footer d-flex justify-content-end",
          class: _vm.type === "dark" ? "bg-transparent" : ""
        },
        [_c("base-pagination", { attrs: { total: "30" } })],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }