var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("send-mail-modal", {
        attrs: { showSendMailModal: _vm.showSendMailModal, support: false },
        on: {
          close: function($event) {
            _vm.showSendMailModal = false
          },
          error: _vm.handleMailSendError
        }
      }),
      _c("base-header", {
        staticClass: "pb-6 pb-8 pt-5 pt-md-8",
        attrs: { type: "gradient-primary" }
      }),
      _c("div", { staticClass: "container-fluid mt--7" }, [
        _c("div", { staticClass: "row mt-5 justify-content-center" }, [
          _c(
            "div",
            { staticClass: "col-xl margin-for-col" },
            [
              _c("product-card", {
                attrs: {
                  icon: "ni ni-mobile-button",
                  title: "Zuma Link",
                  description:
                    "Contamos con una liga de pagos que es la manera más práctica y segura de obtener tus pagos sin necesidad de tener la tarjeta presente. Revoluciona el pago sin presencia de tarjeta SIN CONTRA CARGOS.",
                  button: "Generar Liga de Pago"
                },
                nativeOn: {
                  click: function($event) {
                    return _vm.redirectZumaLink()
                  }
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-xl margin-for-col" },
            [
              _c("product-card", {
                attrs: {
                  icon: "ni ni-money-coins",
                  title: "QPOS",
                  description:
                    "Si cuentas con un Smartphone o Tablet, puedes hacer uso del lector de Zuma Pagos, sin usar tus datos móviles solamente con el Bluetooth de tus Smartphone.",
                  button: "Solicitar lector"
                },
                nativeOn: {
                  click: function($event) {
                    return _vm.showEmailModal()
                  }
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-xl margin-for-col" },
            [
              _c("product-card", {
                attrs: {
                  icon: "ni ni-credit-card",
                  title: "Servicios",
                  description:
                    "Visita nuestra página para consultar servicios adicionales.",
                  button: "Ir"
                },
                nativeOn: {
                  click: function($event) {
                    return _vm.goToZumapagosPage()
                  }
                }
              })
            ],
            1
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }