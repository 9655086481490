<template>
  <div>
    <new-client-modal
      :showCreateClient="showCreateClient"
      v-on:close="closeClientModal"
      v-on:error="handleError"
      v-on:client-created="newClient"
      :idUser="idUser"
      :clean="clean"
      :type="type"
      v-on:cleaned="clean = false"
      v-on:client-updated="clientUpdated"
    ></new-client-modal>

    <base-header type="gradient-primary" class="pb-6 pb-8 pt-5 pt-md-8"></base-header>
    <div class="container-fluid mt--7">
      <div class="row mt-5">
        <div class="col-xl-12">
            <clients-account-table
                title="Administradores"
                :type="type"
                :clientUp="clientUp"
                :updateTable="updateTable"
                v-on:error="handleError"
                v-on:new-client="showCreateClient=true"
                v-on:update-client="prepareUpdateClient"
                v-on:update-table-completed="tableUpdated"

            />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import enums from '../enums/enums';
import handleErrorMixin from "../mixins/handleErrorMixin";
import NewClientModal from './Modals/NewClientModal.vue';
import ClientsAccountTable from './Tables/ClientsAccountTable.vue';

export default {
  components: {
    NewClientModal,
    ClientsAccountTable
  },
  mixins: [handleErrorMixin],
  data() {
    return {
      showCreateClient: false,
      type: enums.ADMIN,
      idUser: 0,
      clean: false,
      clientUp: {},
      updateTable: false
    };
  },
  methods: {
    newClient() {
      this.updateTable = true
      this.showCreateClient = false;
    },
    prepareUpdateClient(row) {
      this.idUser = row.idUser;
      this.showCreateClient = true;
    },
    closeClientModal() {
      this.clean = this.idUser > 0;
      this.showCreateClient = false;
      this.idUser = 0;
    },
    clientUpdated(client) {
      this.idUser = 0;
      this.clientUp = client
      this.showCreateClient = false;
    },
    tableUpdated(){
      this.updateTable = false
      this.clean = true
    }
  },
  mounted() {}
};
</script>
<style>
</style>
