var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row justify-content-center" }, [
    _c("div", { staticClass: "col-lg-12" }, [
      _c("div", { staticClass: "card bg-secondary shadow border-0" }, [
        _c("div", { staticClass: "p-5" }, [
          _vm._m(0),
          _vm._m(1),
          _c("div", [
            _c("h4", { staticClass: "mt-3" }, [_vm._v("DECLARACIONES")]),
            _c("div", { domProps: { innerHTML: _vm._s(_vm.textDeclarations) } })
          ]),
          _c("div", [
            _c("h4", { staticClass: "mt-3" }, [_vm._v("DEFINICIONES")]),
            _c("div", { domProps: { innerHTML: _vm._s(_vm.textDefinitions) } })
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("h2", [_vm._v("Términos y Condiciones")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mt-5" }, [
      _c("h5", [
        _vm._v(
          "\n              CONTRATO DE PRESTACIÓN DE SERVICIOS Y PROCESA- MIENTO DE TRANSACCIONES CON TARJETAS DE CRÉDITO O DEBITO (EN LO SUCESIVO EL “CONTRATO”) QUE CELEBRAN: (I) LA PERSONA FÍSICA O MORAL CUYO NOMBRE; DENOMINACIÓN O RAZÓN SOCIAL HAN SIDO SEÑALADOS EN LA CARÁTULA DE ESTE CONTRATO (“EMPRESA”), REPRESENTA POR LA PERSONA QUE ACEPTA ESTE CONTRATO Y QUIEN SE IDENTIFICA COMO REPRESENTANTE LEGAL DE LA MISMA; Y (II) MERCADO- TECNIA IDEAS Y TECNOLOGÍA, S.A. DE C.V. (“PROCESA- DOR”) QUIENES DE MANERA CONJUNTA EN LO SUCESIVO SE LES DENOMINARÁ COMO LAS “PARTES”, AL TENOR DE LAS SIGUIENTES DECLARACIONES Y CLÁUSULAS.\n            "
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }