var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card h-100 product-option " }, [
    _c("div", { staticClass: "card-body" }, [
      _c("i", { class: _vm.icon }),
      _c("h1", [_vm._v(_vm._s(_vm.title))]),
      _c("hr"),
      _c("p", [_vm._v(_vm._s(_vm.description))])
    ]),
    _c("div", { staticClass: "card-footer" }, [
      _vm.alternateStyle
        ? _c("div", { staticClass: "price" }, [
            _c("div", { staticClass: "front" }, [
              _c("span", { staticClass: "price" }, [
                _vm._v("\n          " + _vm._s(_vm.button) + "\n        ")
              ])
            ])
          ])
        : _c("div", { staticClass: "back" }, [
            _c(
              "a",
              {
                staticClass: "button",
                attrs: { href: "#" },
                on: {
                  click: function($event) {
                    return _vm.$emit("clicked")
                  }
                }
              },
              [_vm._v(_vm._s(_vm.button))]
            )
          ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }