<template>
  <div class="card shadow">
    <update-user-password-modal
      :selectedUserId="selectedUserId"
      :subtitle="subtitle"
      :showChangePassword="showChangePassword"
      v-on:close="closeChangePassword"
    ></update-user-password-modal>
    <div class="card-header border-0">
      <div class="row">
        <div class="col mt-2 align-self-center">
          <div v-if="idBranch > 0">
            <h3 h3 class="mb-0">
              Sucursal:
              <span> {{branch.idBranch}} - {{branch.name}}</span>
            </h3>
            <i class="ni ni-email-83 icon-size-sm align-middle"></i>
            <span :class="detailClass">{{" "}}{{branch.contactMail}}</span>
            &nbsp;
            <i class="ni ni-mobile-button icon-size-sm align-middle"></i>
            <span :class="detailClass">{{" "}}{{branch.contactPhoneNumber}}</span>
          </div>

          <div v-else>
           <h3 h3 class="mb-0">
              Dispositivos
           </h3>
          </div>
        </div>
        <div class="col-xl-3 mt-2 align-self-center">
          <v-select 
            placeholder = 'Todos'
            :options="['Habilitados', 'Desabilitados']"
            @input = "changeTableData"

          ></v-select>          
        </div>
        <div class="col-xl-3 mt-2 text-right align-self-center"  style="{background-color: #0A0;}">
          <base-input
            style="margin-bottom: 0px"
            placeholder="Búsqueda"
            v-model="paginationDTO.searchTerm"
            @keyup.enter="loadData"
          >
          </base-input>
        </div>
        <div v-if="userRol === 'UsersAdmins'" class="col-md-2 mt-2 align-self-center">
          <div class="text-center">
            <a @click="$emit('new-item', branch)" class="btn btn-primary text-white">Nuevo dispositivo</a>
          </div>
        </div>
        <div class="col-md-1 mt-2  text-center align-self-center">
          <download-button
            endpoint="/users/devices/download"
            :payload="downloadPayload"
            reportName="Reporte_dispositivos"
          />
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table class="table align-items-center table-flush"
                  thead-classes="thead-light"
                  tbody-classes="list"
                  :data="tableData">
        <template slot="columns">
          <th v-if="idBranch <= 0">sucursal</th>
          <th>Dispositivo</th>
          <th v-for="n in monthsToDisplay" :key="n">{{monthNames[monthsToDisplay - n]}}</th>
          <th>Estatus</th>
          <th></th>
        </template>

        <template slot-scope="{row}">
          <td v-if="idBranch <= 0">
            <div v-if="row.branchName">
                {{row.branchName}}
            </div>
            <div v-else>
              No asignado
            </div>
          </td>
         
          <td>
            <div 
              @click="goToDetailsItem(row)" 
              style="cursor: pointer; background-color: white" 
              class="h4">
                {{row.deviceName}}
            </div>
            <div >
              {{row.deviceDescription}}
            </div>
            <div >
              {{row.email}}
            </div>
          </td>
      
          <td
            class="text-left"
            v-for="n in monthsToDisplay" :key="n">
            {{ (row.totals[monthNamesEnglish[monthsToDisplay - n]] || "0.00") | currency}}
          </td>
 
          <td>
            <span v-if="row.deviceStatus === 'I'" class="badge badge-pill badge-danger">Deshabilitado</span>
            <span v-if="row.deviceStatus === 'A'" class="badge badge-pill badge-success">Habilitado</span>
          </td>
    
          <td>
            <router-link
              v-if="userRol === 'UsersAdmins'"
              :to="selectedDivice(row)"
            >
              <i v-tooltip.top-center="'Ver detalles del dispositivo'" class="fas fa-desktop icon-size"></i>
            </router-link>
            &nbsp;
            <a
              v-if="userRol === 'UsersAdmins'"
            >
              <i
                @click.prevent="disableItem(row)"
                v-tooltip.top-center="row.deviceStatus === 'A' ? 'Deshabilitar' : 'Habilitar'"
                class="fas fa-exclamation-triangle icon-size"
              ></i>
            </a>
            &nbsp;
            <a>
              <i
                @click.prevent="prepareUpdateItem(row)"
                v-tooltip.top-center="userRol === 'UsersAdmins' ? 'Editar': 'Detalles'"
                :class="userRol === 'UsersAdmins' ? 'fas fa-edit icon-size' : 'fas fa-desktop icon-size'"
              ></i>
            </a>
            &nbsp;
             <router-link
              :to="goProductsDevice(row)">
              <i v-tooltip.top-center="'Productos'" class="fas fa-cube icon-size"></i>
            </router-link>
            &nbsp;

            <a
              v-if="userRol === 'UsersAdmins'">
              <i
                @click.prevent="createQR(row)"
                v-tooltip.top-center="'Crear QR de acceso'"
                class="fas fa-qrcode icon-size"
              ></i>
            </a>
            &nbsp;
            <a 
            v-if="userRol === 'UsersAdmins'">
              <i
                @click.prevent="changePassword(row)"
                v-tooltip.top-center="'Cambiar contraseña'"
                class="fas fa-lock icon-size"
              ></i>
            </a>
            </td>
        </template>

      </base-table>
      <div class="card-footer d-flex justify-content-end">
        <base-pagination
          :total="totalElements"
          :perPage="pagination.size"
          :value="pagination.page"
          @input="updatePagination"
        ></base-pagination>
      </div>
    </div>
  </div>
</template>
<script>

import "vue-select/dist/vue-select.css";
import monthSalesMixin from '../../mixins/monthSalesMixin';
import paginationMixin from "../../mixins/paginationMixin";
import infoCSSClasesMixin from "../../mixins/infoCSSClasesMixin";
import { disableDevice, getBranchInfo, getDevices } from '../../utils/api';
import { processErrorResponse } from '../../mixins/axiosResponseMixin';
import UpdateUserPasswordModal from '../Modals/UpdateUserPasswordModal.vue';
import DownloadButton from '../Buttons/DownloadButton.vue';
import { paginationLimit } from '../../utils/catalog';

  export default {
  components: { UpdateUserPasswordModal, DownloadButton },
    name: 'devices-table',
    mixins: [monthSalesMixin, paginationMixin, infoCSSClasesMixin],
    props: {
      idBranch: Number,
      type: String,
      refreshTable: Boolean,
      updateTable: Boolean,
      deviceUpdated: {},
      showSearch: {
        default: true,
      },
      hiddeTotalsColumns: {
        default: false
      }
    },
    data() {
      return {
        branch: {
        },
        tableData: [],
        totalElements: 0,
        paginationDTO: {
          searchTerm: "",
          offset: 0,
          limit: paginationLimit,
          status: 'ALL'
        },
        showChangePassword: false,
        selectedUserId: 0,
        subtitle: '',
        downloadPayload: {
          searchTerm: "",
          offset: 0,
          limit: paginationLimit,
          status: "ALL",
          orderBy: "DESC"
        }
      }
    },
    computed: {
      userRol() {
        return  this.$store.getters.jwtRole
      }
    },
    methods: {
      updatePagination(page) {
        if (this.pagination.page !== page){
          if (page === 1){
            this.paginationDTO.offset = 0
          }else{
            let offset = page * this.paginationDTO.limit - this.paginationDTO.limit
            this.paginationDTO.offset = offset
          }
          this.pagination.page = page;
          this.loadData();
        }
      },
      loadData() {
        this.downloadPayload.searchTerm = this.paginationDTO.searchTerm
        let load = this.$loading.show({loader: 'dots'})
        getDevices(this.paginationDTO)
        .then(response => {
          load.hide()
          if (response.data.statusCode === 200){
            this.totalElements = response.data.result.count
            this.tableData = response.data.result.list
            this.downloadPayload.limit =  response.data.result.count
        }else{
            processErrorResponse(response.data, this.$toasted)
          }
        })
        .catch(error => {
          load.hide()
          this.$emit('error', error)
        })
      },
      changeTableData(e) {
        switch (e) {
          case 'Habilitados':
              this.paginationDTO.status = 'A'
            break;
          case 'Desabilitados':
              this.paginationDTO.status = 'I'
            break;
          default:
              this.paginationDTO.status = 'ALL'
            break;
        }
        this.downloadPayload.status = this.paginationDTO.status
        this.loadData()
      },
      disableItem(row) {
        let loader = this.$loading.show({loader: 'dots'})
        disableDevice(row.idUser)
        .then(response => {
          loader.hide()
          if (response.data.statusCode === 200) {
            this.$toasted.show("Operacion exitosa")
            if (this.paginationDTO.status === 'ALL'){
              row.deviceStatus = row.deviceStatus === 'A' ? 'I' : 'A'
            }else{
              let index = this.tableData.findIndex(e => e.idUser === row.idUser)
              if ( index >= 0 )  
                this.tableData.splice(index,1) 
            }
          }else{
            processErrorResponse(response.data, this.$toasted)
          }
        })
        .catch(error => {
          loader.hide()
          this.$emit('error', error)
        })
      },
      prepareUpdateItem(row){
        this.$emit("update-item", row);
      },
      goToDetailsItem(row) {
        this.$router.push({
          name: 'transactions-details', 
          query: { idDevice: row.idUser }
        });
      },
      getBranchData() {
        getBranchInfo(this.idBranch)
        .then(response => {
          if (response.data.statusCode === 200) {
            this.branch = response.data.result
          }else{
            processErrorResponse(response.data, this.$toasted)
          }
        })
        .catch(error => {
          this.$emit('error', error)
        })
      },
      createQR(row){
        let loader = this.$loading.show({loader: 'dots'})
         this.axios.post(`/auth/code`,
            {idUserDevice: row.idUser},
            {responseType: 'blob'}
            )
            .then(response => {
              loader.hide()
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', Date.now() + '.png');
              document.body.appendChild(link);
              link.click();
            })
            .catch(error => {
              loader.hide()
              this.$emit('error', error)
            });
      },
      selectedDivice(row){
        return `/device-detail/${row.idUser}`;
      },
      tickets(row){
        return `/tickets/${row.idUser}`;
      },
      changePassword(row){
        this.subtitle = row.deviceDescription
        this.selectedUserId = Number(row.idUser)
        this.showChangePassword = true
      },
      closeChangePassword(){
        this.subtitle = ''
        this.selectedUserId = 0
        this.showChangePassword =false
      },
       goProductsDevice(row){
        return `/productsDevice/${row.idUser}`;
      },
    },
    mounted() {
      this.pagination.size = this.paginationDTO.limit
      if (this.hiddeTotalsColumns){
        this.monthsToDisplay = 0
      }
      this.initializeMonthData();
      this.loadData();
    },
    watch: {
      idBranch: {
        handler(newValue) {
          if (newValue > 0) {
            this.getBranchData()
            this.paginationDTO.idBranch = newValue
          }
        }
      },
      deviceUpdated: {
        handler(newValue){
          let index = this.tableData.findIndex(e => e.idUser === newValue.idUser);
          if (index < 0) {
            index = this.tableData.length;
          }
          this.tableData[index].deviceDescription = newValue.deviceDescription
          this.tableData[index].deviceName = newValue.deviceName
          this.tableData[index].email = newValue.email
          this.$emit("update-table-completed");
        }
      },
      updateTable: {
        handler(newValue) {
          if (newValue) {
            this.loadData()
            this.$emit('table-updated')
          }
        }
      }
    }
  }
</script>
<style lang="scss">

   @media (max-width: 1400px) {
    .md-screen-hide{
      display: none;
    }
  }
  .ligth-text {
    color: #828f7f;
  }

  .parentBranch{
    background-color: cornsilk;
  }
  .deleted{
    background-color: darksalmon;
  }
  .icon-size {
    font-size: 1rem;
  }
  .tooltip {
  display: block !important;
  z-index: 10000;

  .tooltip-inner {
    background: black;
    color: white;
    border-radius: 16px;
    padding: 5px 10px 4px;
  }

  .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
    border-color: black;
  }

  &[x-placement^="top"] {
    margin-bottom: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 0 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      bottom: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="bottom"] {
    margin-top: 5px;

    .tooltip-arrow {
      border-width: 0 5px 5px 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      top: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="right"] {
    margin-left: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 5px 0;
      border-left-color: transparent !important;
      border-top-color: transparent !important;
      border-bottom-color: transparent !important;
      left: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[x-placement^="left"] {
    margin-right: 5px;

    .tooltip-arrow {
      border-width: 5px 0 5px 5px;
      border-top-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      right: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[aria-hidden='true'] {
    visibility: hidden;
    opacity: 0;
    transition: opacity .15s, visibility .15s;
  }

  &[aria-hidden='false'] {
    visibility: visible;
    opacity: 1;
    transition: opacity .15s;
  }
}
</style>
