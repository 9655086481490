<template>
  <div>
    <send-mail-modal
      :showSendMailModal="showSendMailModal"
      v-on:close="showSendMailModal=false"
      v-on:error="handleMailSendError"
    />
    <base-header type="gradient-primary" class="pb-6 pb-8 pt-5 pt-md-8"></base-header>
    <div class="container-fluid mt--7">
      <div class="row mt-5 justify-content-center">
        <div class="col-xl-4">
          <product-card
            icon="ni ni-email-83"
            title="Correo Electrónico"
            description="Nuestro equipo de soporte te respondera a la brevedad, nuestros horarios de atencion son de 8:00 a 20:00 de lunes a viernes"
            button="Enviar correo"
            @click.native='showEmailModal()'
          />
        </div>
        <div class="col-xl-1">
        </div>
        <div class="col-xl-4">
          <product-card
            icon="ni ni-email-83"
            title="Mesa de Ayuda"
            description="Estamos Listos para atenderte y resolver todas tus preguntas. No dudes en contactarnos por WhatsApp."
            button="811 306 0714"
            @click.native='goWhatsAppWeb()'
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SendMailModal from './Modals/SendMailModal';
import ProductCard from './Cards/ProductCard';
export default {
  components:{
    SendMailModal,
    ProductCard,
  },
  data() {
    return {
      showSendMailModal: false,
    };
  },
  methods: {
    showEmailModal(){
      this.showSendMailModal=true;
    },
    handleMailSendError(error){
      this.showSendMailModal = false;
      this.$emit('error', error);
    },
    goWhatsAppWeb(){
      window.open('https://wa.me/+5218113060714')
    }
  },
};
</script>
<style lang="scss" scoped>
</style>