<template>
  <div class="row justify-content-center">
    <div class="col-lg-12">
      <div class="card bg-secondary shadow border-0">
        <div class="p-5">
           <div>
            <h2>Términos y Condiciones</h2>
          </div>
          <div class="mt-5">
              <h5>
                CONTRATO DE PRESTACIÓN DE SERVICIOS Y PROCESA- MIENTO DE TRANSACCIONES CON TARJETAS DE CRÉDITO O DEBITO (EN LO SUCESIVO EL “CONTRATO”) QUE CELEBRAN: (I) LA PERSONA FÍSICA O MORAL CUYO NOMBRE; DENOMINACIÓN O RAZÓN SOCIAL HAN SIDO SEÑALADOS EN LA CARÁTULA DE ESTE CONTRATO (“EMPRESA”), REPRESENTA POR LA PERSONA QUE ACEPTA ESTE CONTRATO Y QUIEN SE IDENTIFICA COMO REPRESENTANTE LEGAL DE LA MISMA; Y (II) MERCADO- TECNIA IDEAS Y TECNOLOGÍA, S.A. DE C.V. (“PROCESA- DOR”) QUIENES DE MANERA CONJUNTA EN LO SUCESIVO SE LES DENOMINARÁ COMO LAS “PARTES”, AL TENOR DE LAS SIGUIENTES DECLARACIONES Y CLÁUSULAS.
              </h5>
          </div>
          <div>
            <h4 class="mt-3">DECLARACIONES</h4>
            <div v-html="textDeclarations">
            </div>
          </div>
           <div>
            <h4 class="mt-3">DEFINICIONES</h4>
            <div v-html="textDefinitions">
            </div>
          </div>
        </div>
       
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'register',
    data() {
      return {
        textDeclarations: `<DIV ALIGN="justify">
                            I. Las <strong>PARTES</strong> declaran que el presente <strong>CONTRATO</strong> y sus <strong>Cláusulas</strong> son obligatorios para ambas, por tanto, constituye un acuerdo total entre las mismas para la prestación de los servicios y expresamente convienen que con la aceptación electrónica o de cualquier otra forma del mismo por parte de la <strong>EMPRESA</strong>, tendrá plenos efectos jurídicos entre las <strong>PARTES</strong>.
                            <br><br>II. La <strong>EMPRESA</strong> declara que en caso de ser persona física es mayor de edad, con plena capacidad jurídica para obligarse de conformidad con este <strong>CONTRATO</strong> y tener medios suficientes para hacer frente a sus obligaciones. Para el caso de representar a una persona moral, declara que su representada

                            se encuentra debidamente constituida y que cuenta con las facultades legales necesarias para comprometer a su representada de acuerdo con este <strong>CONTRATO</strong> y que las mismas no le han sido revocadas, modificadas o limitadas en forma alguna. En consecuencia, manifiesta que todos los datos precisados en el <strong>CONTRATO</strong> y la carátula del mismo son reales, veraces y comprobables. Del mismo modo, tiene contratada una cuenta de depósito a la vista (“Cuenta de Cheques”), cuyos datos han sido especificados en la carátula de este <strong>CONTRATO</strong>, aceptando que en ellas le sean abonadas o cargadas las cantidades que se generen de conformidad con el presente <strong>CONTRATO</strong>. Ha adquirido lícitamente un Terminal Punto de Venta (“<strong>TPV</strong>) y desea celebrar el presente <strong>CONTRATO</strong> en los términos y condiciones que se pactarán en el clausulado del mismo.
                            <br><br>
                            III. Declara el <strong>PROCESADOR</strong> que es una sociedad mercantil debidamente constituida de conformidad con las leyes de los Estados Unidos Mexicanos según consta en la Escritura Pública número 21,660, de fecha 7 de octubre de 2004, otorgada ante la fe del licenciado Héctor Manuel Cárdenas Villarreal, Notario Público número 201 de la Ciudad de México, debidamente inscrita en el Registro Público de Comercio de la Ciudad de México, bajo el Folio Mercantil número 327600 de fecha 3 de noviembre de 2004. Se encuentra al corriente en el cumplimiento de sus obligaciones fiscales. Y que su domicilio se ubica en Corregidora 92, Colonia Miguel Hidalgo, C.P. 14260, en la Ciudad de México. debidamente inscrita en el Registro Público de Comercio de la Ciudad de México, bajo el Folio Mercantil número 327600 de fecha 3 de noviembre de 2004. Se encuentra al corriente en el cumplimiento de sus obligaciones fiscales. Y que su domicilio se ubica en Corregidora 92, Colonia Miguel

                            Hidalgo, C.P. 14260, en la Ciudad de México. Asimismo, ha obtenido el registro ante las entidades correspondientes para operar este modelo de negocio conocido como facilitador de pagos, agregador o proveedor de servicios de pagos y cuenta con las afiliaciones bancarias para aceptar pagos con Tarjetas. Que con la aceptación expresa y de manera electrónica por parte de la <strong>EMPRESA</strong> a este Contrato, el <strong>PROCESADOR</strong> queda expresamente obligado de conformidad con el clausulado de este Contrato.

                          </DIV>`,
        textDefinitions: `<DIV ALIGN="justify">
                            Las siguientes definiciones podrán ser usadas en singular o plural en este <strong>CONTRATO</strong>, manteniendo su significado.

                            <br><br>Banco Adquirente: Es la entidad Participante en Redes que provee servicios de pagos al <strong>PROCESADOR</strong> en las Redes de Pagos con Tarjeta.
                            Banco Emisor: Es la entidad Participante en Redes que expide Tarjetas.
                            Comisión: Cantidad descontada por el <strong>PROCESADOR</strong> del importe asociado a una autorización solicitada por la <strong>EMPRESA</strong> a través de la <strong>TPV</strong>. El importe de la Comisión se obtiene multiplicando el importe autorizado a través de la TPV por un porcentaje pactado entre las <strong>PARTES</strong> y adicionando a la cantidad resultante el Impuesto al Valor Agregado. La Comisión máxima puede ser consultada en el sitio web <a target='_blank' rel='noopener noreferrer' href='http://www.spug.com.mx'>www.spug.com.mx</a>.

                            <br><br>Costo de Depósito: Es el costo acordado entre las <strong>PARTES</strong> por depositar los importes asociados a las autorizaciones de pagos con Tarjetas a la cuenta CLABE de importes asociados a las autorizaciones de pagos con Tarjetas a la cuenta <strong>CLABE</strong> de la <strong>EMPRESA</strong>. El Costo de Depósito máximo puede ser consultado en el sitio web www.spug.com.mx.
                          </DIV>
        `
      }
    }
  }
</script>
<style>

</style>
