<template>
  <div class="card shadow">
    <div class="card-header border-0">
      <div class="row">
        <div class="col mt-2 align-self-center">
          <h3 class="mb-0">
            Comisiones
          </h3>
        </div>
      </div>
    </div>

    <div class="table-responsive m-2">
      <b-container>
        <b-row>
          <b-col cols="3" class="text-center  align-self-center" >
            <h4>Banco</h4> 
          </b-col>
          <b-col cols="3" class="text-center  align-self-center" >
            <h4>Tipo</h4> 
          </b-col>
          <b-col class="text-center">
            <h4>Tasa</h4>
          </b-col>
          <b-col class="text-center">
            <h4>Sobre Tasa</h4>
          </b-col>
        </b-row>
        <b-row v-for="fee in fees" v-bind:key="fee.id" class="mt-3">
          <b-col cols="3" class="text-center">
            {{fee.bank}}
          </b-col>
          <b-col cols="3" class="text-center  align-self-center" >
            <label lass="form-control-label">
              {{fee.keyDesc}}
            </label>
          </b-col>
          <b-col >
            <base-input
              class="align-self-center"
              style="margin-bottom: 0px"
              input-classes="form-control-alternative"
              v-model="fee.baseFee"
              validation="number"
              :maxlength="5"
            ></base-input>
          </b-col>
          <b-col>
            <base-input
              class="align-self-center"
              style="margin-bottom: 0px"
              input-classes="form-control-alternative"
              v-model="fee.overFee"
              validation="number"
              :maxlength="5"
            ></base-input>
          </b-col>
        </b-row>
      </b-container>
      <div class="bg-white border-0">
        <div class="row align-items-center">
          <div class="col-12 text-right">
            <base-button type="primary" class="my-4 ml-3 mr-3"  @click="saveFees" >Guardar</base-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import "vue-select/dist/vue-select.css";
import monthSalesMixin from '../../mixins/monthSalesMixin';
import paginationMixin from "../../mixins/paginationMixin";
import infoCSSClasesMixin from "../../mixins/infoCSSClasesMixin";
import { getFees, saveFees } from '../../utils/api';
import { processErrorResponse } from '../../mixins/axiosResponseMixin';
import { isEmptyOrNull } from '../../utils/utilMethods';

  export default {
    name: 'fee-table',
    mixins: [monthSalesMixin, paginationMixin, infoCSSClasesMixin],
    props: {
      accountId: Number,
    },
    data() {
      return {
        fees: []
      }
    },
    watch: {
      accountId: {
        handler(newValue){
          if(newValue > 0){
            this.loadData();
          }
        }
      },
    },
    methods: {
      currencyFees() {
        this.fees.forEach(fee => {
          fee.overFee = parseFloat(fee.overFee).toFixed(2)
          fee.baseFee = parseFloat(fee.baseFee).toFixed(2)
        })
      },
      changeFeesToNumber(){
        const f = this.fees.map(o => ({...o}))
        f.forEach(fee => {
          fee.overFee = parseFloat(parseFloat(fee.overFee).toFixed(2))
          fee.baseFee = parseFloat(parseFloat(fee.baseFee).toFixed(2))
        })
        return f
      },
      saveFees() {
        if (this.checkFees()) {
          let f = this.changeFeesToNumber()
          let loader = this.$loading.show({loader: 'dots'})
          let dto = {idAccount: this.accountId, fees: f}
          saveFees(dto)
          .then(response => {
            loader.hide()
            if (response.data.statusCode === 200) {
              this.$toasted.show("Comisiones actualizadas correctamente")
            }else{
              processErrorResponse(response.data, this.$toasted)
            }
          })
          .catch(error => {
            loader.hide()
            this.$emit('error', error)
          })
        }
      },
      checkFees(){
        for (let fee of this.fees) {
          if (isEmptyOrNull(fee.overFee) || isEmptyOrNull(fee.baseFee)) {
            this.$toasted.show(`Información incompleta: ${fee.keyDesc}`)
            return false
          }
          if(fee.overFee > 99.99 || fee.baseFee > 99.99){
            this.$toasted.show(`Los valores de las comisiones no pueden exceder 99.99: ${fee.keyDesc}`)
            return false
          }
        }
        return true
      },
      loadData() {
        getFees(this.accountId)
        .then(response => {
          if (response.data.statusCode === 200){
            this.fees = response.data.result
            this.currencyFees()
          }else{
            processErrorResponse(response.data, this.$toasted)
          }
        })
        .catch(error => {
          this.$emit('error', error)
        })
      }
    },
    mounted() {
       if (this.accountId > 0) {
        this.loadData();
       }
    }
  }
</script>
<style lang="scss">

</style>
