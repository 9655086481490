<template>
  <div>
    <base-header type="gradient-primary" class="pb-6 pb-8 pt-5 pt-md-8"></base-header>
    <div class="container-fluid mt--7">
      <div class="row mt-5">
        <div class="col-xl-12">
          <partner-acount-table
            v-on:error="handleError"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import handleErrorMixin from "../mixins/handleErrorMixin";
import PartnerAcountTable from './Tables/PartnerAcountTable.vue';

export default {
  mixins: [handleErrorMixin],
  components: {
    PartnerAcountTable
  },
  data() {
    return {
      refreshSalersTable: false
    };
  }
};
</script>