<template>
  <div>
    <base-header type="gradient-primary" class="pb-6 pb-8 pt-5 pt-md-8">
      <!-- <card-stats :period=period></card-stats> -->
    </base-header>
    <div class="container-fluid mt--7">
      <div class="row mt-5">
        <div class="col-xl-12">
            <card shadow>
              <div>
                <h3 h3 class="mb-0">
                  Sesión: {{idSession}}
                </h3>
                <div v-if="idDevice">
                  <h3 h3 class="mb-0">
                    <span> Dispositivo: {{device.deviceName}}</span>
                  </h3>
                  <span :class="detailClass">{{"Descripción: "}}{{device.deviceDescription}}</span>
                  &nbsp;
                  <span :class="detailClass">{{"Email: "}}{{device.email}}</span>
                </div>
                 <div v-if="idBranch">
                  <h3 h3 class="mb-0">
                    <span> Sucursal: {{branch.name}}</span>
                  </h3>
                  <span :class="detailClass">{{"Clave interna: "}}{{branch.internalKey}}</span>
                  <tr />
                  <span :class="detailClass">{{"Email: "}}{{branch.contactMail}}</span>
                </div>
              </div>
              <transactions-table-v-2
                :idBranch="idBranch"
                :idDevice="idDevice"
                :idSession="idSession"
              />
            </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import handleErrorMixin from '../mixins/handleErrorMixin';
import periodSearchMixin from '../mixins/periodSearchMixin';
import infoCSSClasesMixin from '../mixins/infoCSSClasesMixin';
import TransactionsTableV2 from './Tables/TransactionsTableV2.vue';
import { getBranchInfo, getDeviceInfo } from '../utils/api';
import { processErrorResponse } from '../mixins/axiosResponseMixin';

  export default {
    components: {
      TransactionsTableV2
    },
    mixins: [handleErrorMixin, periodSearchMixin, infoCSSClasesMixin],
    data() {
      return {
        idSession: Number(this.$route.query.idSession),
        idBranch: Number(this.$route.query.idBranch),
        idDevice: Number(this.$route.query.idDevice),
        branch: {},
        device: {}
      };
    },
    methods: {
      updateState(){
      },
      getBranchData() {
         let loader = this.$loading.show({loader: 'dots'})
        getBranchInfo(this.idBranch)
        .then(response => {
          loader.hide()
          if (response.data.statusCode === 200) {
            this.branch = response.data.result
          }else{
            processErrorResponse+(response.data, this.$toasted)
          }
        })
        .catch(error => {
          loader.hide()
          this.$emit('error', error)
        })
      },
      getDeviceInfo(){
        let loader = this.$loading.show({loader: 'dots'})
        getDeviceInfo(this.idDevice)
        .then(response => {
          loader.hide()
          if (response.data.statusCode === 200) {
            this.device = response.data.result
          }else{
            processErrorResponse(response.data, this.$toasted)
          }
        })
        .catch(error => {
          loader.hide()
          this.$emit('error', error)
        })
      }
    },
    mounted() {
      if (this.idBranch > 0) {
        this.getBranchData()
      }
      if (this.idDevice > 0) {
        this.getDeviceInfo()
      }
    }
  };
</script>
<style>
.card-body {
  padding: 10px
}
</style>
