var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("assign-item-modal", {
        attrs: {
          showAssignItem: _vm.showAssignItem,
          type: "partner-account",
          isMultipleSelection: false
        },
        on: {
          close: function($event) {
            _vm.showAssignItem = false
          },
          selectedItems: _vm.itemsSelected,
          error: _vm.handleError
        }
      }),
      _c(
        "b-container",
        { staticStyle: { padding: "0px" } },
        [
          _c(
            "b-row",
            [
              _c("b-col", { attrs: { cols: "9" } }, [
                _c("label", { staticClass: "form-control-label" }, [
                  _vm._v("\n          Vendedor\n        ")
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.item.userPartnerName,
                      expression: "item.userPartnerName"
                    }
                  ],
                  staticClass: "form-control",
                  staticStyle: {
                    cursor: "pointer",
                    "background-color": "white"
                  },
                  attrs: { alternative: "", readonly: "true", required: "" },
                  domProps: { value: _vm.item.userPartnerName },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.item, "userPartnerName", $event.target.value)
                    }
                  }
                })
              ]),
              _c(
                "b-col",
                {
                  staticClass: "align-self-center text-right",
                  attrs: { cols: "3" }
                },
                [
                  _c(
                    "base-button",
                    {
                      staticClass: "my-4 ml-3 mr-3",
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          _vm.showAssignItem = true
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.getTextPartnerButton()))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }