<template>
  <div>
    <base-header type="gradient-primary" class="pb-6 pb-8 pt-5 pt-md-8"></base-header>
    <new-enterprise-modal
      :showCreateAccount="showCreateAccount"
      :ownerId="selectedAccountId"
      v-on:close="closeEnterpriseModal"
      v-on:error="handleError"
      v-on:enterprise-created="updateNewAccount"
      :itemId="enterpriseId"
      :clean="clean"
      :type="type"
      v-on:cleaned="clean = false"
      v-on:Account-updated="accountUpdated"
    ></new-enterprise-modal>
    <assign-item-modal
      :showAssignItem="showAssignItem"
      :type="'partner-account'"
      :isMultipleSelection="false"
      v-on:close="showAssignItem=false"
      v-on:selectedItems="itemsSelected"
      v-on:error="handleError"
    ></assign-item-modal>
    <div class="container-fluid mt--9">
      <div class="row mt-5 justify-content-center">
        <div class="col-xl-12">
          <card shadow>
            <form @submit.prevent>
              <b-tabs
                pills
                v-model="tabRootIndex"
                vertical
                content-class="mt-3"
                no-key-nav
              >
                <b-tab title="Detalles de la cuenta" title-item-class="text-center">
                  <h2>
                    Detalles de la cuenta
                  </h2>
                  <b-tabs
                    pills
                    v-model="tapAccountIndex"
                    content-class="mt-3"
                    no-key-nav
                    >
                      <b-tab :title-item-class="canContinue() ? 'flex-fill text-center' : 'flex-fill text-center'" >
                        <span slot="title">Generales</span>
                        <b-container style="width:100%" >
                          <b-row>
                            <b-col>
                              <base-input
                                input-classes="form-control-alternative"
                                label="Nombre de la cuenta: "
                                v-model="item.name"
                                :error = "validateField('name')"
                                :capitalize='true'
                                :maxlength="64"
                              ></base-input>
                            </b-col>
                            <b-col>
                              <base-input
                                input-classes="form-control-alternative"
                                label="Clave interna:"
                                v-model="item.internalKey"
                                :error = "validateField('internalKey')"
                                :capitalize='true'
                                :maxlength="16"
                              ></base-input>
                            </b-col>
                          </b-row>

                          <b-row>
                            <b-col>
                              <base-input
                                input-classes="form-control-alternative"
                                label="Nombre de contacto: "
                                v-model="item.contactName"
                                :error = "validateField('contactName')"
                                :capitalize='true'
                                :maxlength="128"
                              ></base-input>
                            </b-col>
                          </b-row>

                          <b-row>
                            <b-col>
                              <base-input
                                input-classes="form-control-alternative"
                                label="Número Telefonico: "
                                v-model="item.contactPhoneNumber"
                                :error = "validateField('contactPhoneNumber')"
                                :maxlength="16"
                                validation="number"
                              ></base-input>
                            </b-col>
                            <b-col>
                              <base-input
                                input-classes="form-control-alternative"
                                label="Extención:"
                                v-model="item.contactPhoneExt"
                                :error = "validateField('contactPhoneExt')"
                                :maxlength="8"
                                validation="number"
                              ></base-input>
                            </b-col>
                          </b-row>

                          <b-row>
                            <b-col>
                              <base-input
                                input-classes="form-control-alternative"
                                label="Celular: "
                                v-model="item.contactMobileNumber"
                                :error = "validateField('contactMobileNumber')"
                                :capitalize='true'
                                :maxlength="16"
                                validation="number"
                              ></base-input>
                            </b-col>
                            <b-col>
                              <base-input
                                input-classes="form-control-alternative"
                                label="Email: "
                                v-model="item.contactMail"
                                :error = "validateField('contactMail')"
                                :maxlength="64"
                              ></base-input>
                            </b-col>
                          </b-row>
                          <partner-selector
                            :partnerSelected="item"
                            v-on:partner-selected="partnerSelected"
                          ></partner-selector>
                        </b-container>
                      </b-tab>
                      <b-tab :title-item-class="canContinue() ? 'flex-fill text-center' : 'flex-fill text-center'" >
                        <span slot="title">Dirección</span>
                        <b-container>
                          <b-row class="mt-4">
                             <b-col>
                              <base-input
                                input-classes="form-control-alternative"
                                label="Código postal:"
                                v-model="item.zipcode"
                                :error = "validateField('zipcode')"
                                :capitalize='true'
                                :maxlength="5"
                              ></base-input>
                            </b-col>
                            <b-col>
                              <base-input
                                input-classes="form-control-alternative"
                                label="Calle:"
                                v-model="item.street"
                                :error = "validateField('street')"
                                :capitalize='true'
                                :maxlength="256"
                              ></base-input>
                            </b-col>
                          </b-row>
                          <b-row >
                            <b-col>
                              <base-input
                                input-classes="form-control-alternative"
                                label="Número ext:"
                                v-model="item.extNumber"
                                :error = "validateField('extNumber')"
                                :capitalize='true'
                                :maxlength="16"
                              ></base-input>
                            </b-col>
                            <b-col>
                              <base-input
                                input-classes="form-control-alternative"
                                label="Número int:"
                                v-model="item.intNumber"
                                :error = "validateField('intNumber')"
                                :capitalize='true'
                                :maxlength="16"
                              ></base-input>
                            </b-col>
                          </b-row>

                          <b-row>
                            <b-col>
                              <base-input
                                input-classes="form-control-alternative"
                                label="Colonia:"
                                v-model="item.suburb"
                                :error = "validateField('suburb')"
                                :capitalize='true'
                                :maxlength="256"
                              ></base-input>
                            </b-col>
                            <b-col>
                              <base-input
                                input-classes="form-control-alternative"
                                label="Municipio:"
                                v-model="item.county"
                                :error = "validateField('county')"
                                :capitalize='true'
                                :maxlength="64"
                              ></base-input>
                            </b-col>
                          </b-row>

                          <b-row>
                            <b-col>
                              <base-input
                                input-classes="form-control-alternative"
                                label="Ciudad:"
                                v-model="item.city"
                                :error = "validateField('city')"
                                :capitalize='true'
                                :maxlength="64"
                              ></base-input>
                            </b-col>
                            <b-col>
                              <base-input
                                input-classes="form-control-alternative"
                                label="Estado:"
                                v-model="item.state"
                                :error = "validateField('state')"
                                :capitalize='true'
                                :maxlength="64"
                              ></base-input>
                            </b-col>
                          </b-row>

                          <b-row>
                            <b-col>
                              <base-input
                                input-classes="form-control-alternative"
                                label="Tipo Cliente (Marketing):"
                                v-model="item.country"
                                :error = "validateField('country')"
                                :capitalize='true'
                                :maxlength="64"
                              ></base-input>
                            </b-col>
                          </b-row>
                        </b-container>

                      </b-tab>
                      <b-tab :title-item-class="canContinue() ? 'flex-fill text-center' : 'flex-fill text-center'" >
                        <span slot="title">Encuesta</span>
                        <b-container>
                          <b-row v-if="false">
                            <b-col>
                              <base-input
                                input-classes="form-control-alternative"
                                label="Máximo de ventas por día:"
                                v-model="item.maxByDay"
                                :error = "validateField('maxByDay')"
                                validation="number"
                              ></base-input>
                            </b-col>
                            <b-col>
                              <base-input
                                input-classes="form-control-alternative"
                                label="Máximo de ventas por mes:"
                                v-model="item.maxByMonth"
                                :error = "validateField('maxByMonth')"
                                validation="number"
                              ></base-input>
                            </b-col>
                          </b-row>
                          <b-row>
                          </b-row>
                          <b-row>
                            <b-col>
                              <base-input
                                input-classes="form-control-alternative"
                                label="Texto "
                                v-model="item.surveyQuestion"
                                :error = "validateField('surveyQuestion')"
                                :maxlength="256"
                              ></base-input>
                            </b-col>
                            <b-col>
                              <base-input
                                input-classes="form-control-alternative"
                                label="Código"
                                v-model="item.surveyCode"
                                :error = "validateField('surveyCode')"
                                :maxlength="16"
                              ></base-input>
                            </b-col>
                          </b-row>
                        </b-container>
                      </b-tab>
                  </b-tabs>
                  <div  class="bg-white border-0">
                    <div class="row align-items-center">
                      <div class="col-12 text-right">
                        <base-button type="primary" class="my-4 ml-3 mr-3"  @click="nextStep" >Actualizar</base-button>
                      </div>
                    </div>
                  </div>
                </b-tab>
                <!-- <b-tab title="Comisiones" title-item-class="text-center">
                  <fee-tables
                    :accountId="selectedAccountId"
                  />
                </b-tab> -->
                <b-tab title="Empresas" title-item-class="text-center">
                  <enterprises-table
                    :idAccount="selectedAccountId"
                    :updateTable="updteTable"
                    :hiddeTotalsColumns="true"
                    v-on:table-loaded="tableLoaded"
                    v-on:new-item="newItem"
                    v-on:error="handleError"
                    v-on:update-item="prepareUpdateItem"

                    :type="type">
                  </enterprises-table>
                </b-tab>
              </b-tabs>
            </form>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import handleErrorMixin from "../mixins/handleErrorMixin";
import flatpickr from "flatpickr";
import { Spanish } from "flatpickr/dist/l10n/es.js";
flatpickr.localize(Spanish);
import "flatpickr/dist/flatpickr.css";
import enumRoles from '../enums/enumRoles';
import { validateMail } from '../utils/utilMethods';
import { assingAccounts, getAccountInfo, updateAccount } from '../utils/api';
import { processErrorResponse } from '../mixins/axiosResponseMixin';
import { isEmptyOrNull, simpleCurrency } from '../utils/generalMethods';
import FeeTables from './Tables/FeeTables.vue';
import enums from '../enums/enums';
import EnterprisesTable from './Tables/EnterprisesTable.vue';
import NewEnterpriseModal from './Modals/NewEnterpriseModalV3.vue';
import AssignItemModal from './Modals/AssignItemModal.vue';
import PartnerSelector from '../components/PartnerSelector.vue';

export default {
  mixins: [handleErrorMixin],
  components: {
    FeeTables,
    EnterprisesTable,
    NewEnterpriseModal,
    AssignItemModal,
    PartnerSelector
  },
  data() {
    return {
      rolAdmin: enumRoles.ADMIN,
      tapAccountIndex: 0,
      tabRootIndex: 0,
      numberTabs: 2,
      textButton: 'Siguiente',
      selectedAccountId: 0,
      showUserModal: false,
      showErrorMessages: false,
      item: {
        name: '',
        contactName: '',
        contactPhoneNumber: '',
        contactPhoneExt: '',
        contactMobileNumber: '',
        contactMail: '',
        street: '',
        extNumber: '',
        intNumber: '',
        suburb: '',
        county: '',
        city: '',
        state: '',
        country: '',
        inheritable: '',
        internalKey: '',
        maxByDay: 0,
        maxByMonth: 0,
        surveyCode: '',
        surveyQuestion: '',
        zipcode: 0,
        userPartnerName: '',
        userPartnerId: ''
      },
      emptyItem: {
        name: '',
        contactName: '',
        contactPhoneNumber: '',
        contactPhoneExt: '',
        contactMobileNumber: '',
        contactMail: '',
        street: '',
        extNumber: '',
        intNumber: '',
        suburb: '',
        county: '',
        city: '',
        state: '',
        country: '',
        inheritable: '',
        internalKey: '',
        maxByDay: 0,
        maxByMonth: 0,
        surveyCode: '',
        surveyQuestion: '',
        zipcode: 0,
        userPartnerName: '',
        userPartnerId: ''
      },
      fees: [
      ],
      type: enums.ENTERPRISE,
      updteTable: false,
      showCreateAccount: false,
      clean: Boolean,
      enterpriseId: 0,
      showAssignItem: false,
      userPartnerId: ''
    };
  },

  computed: {
    canalesP() {
      if (typeof this.operativas.tarjetaPresente.canal === "string") {
        return this.operativas.tarjetaPresente.canal.split(",");
      }
      return this.operativas.tarjetaPresente.canal;
    },
    canalesNP() {
      if (typeof this.operativas.tarjetaNoPresente.canal === "string") {
        return this.operativas.tarjetaNoPresente.canal.split(",");
      }
      return this.operativas.tarjetaNoPresente.canal;
    },
    userRole() {
      return this.$store.getters.jwtRole;
    }
  },

  methods: {
    closeEnterpriseModal() {
      this.showCreateAccount = false;
      this.enterpriseId = 0;
    },
    updateActiveTab(event) {
      this.activeTab = event.title;
    },   
    currencyAmounts() {
      this.item.maxByDay = simpleCurrency(this.item.maxByDay)
      this.item.maxByMonth = simpleCurrency(this.item.maxByMonth)
    },
    previusStep() {
      this.tapAccountIndex --;
    },
    nextStep() {
      if (!this.canContinue()) {
        this.$toasted.show('Es necesario completar todos los campos requeridos para continuar')
        return
      }
      this.saveAccount()

    },
    canContinue() {
      var keys = Object.keys(this.item)
      for (let i = 0; i < keys.length; i++) {
        if (this.validateField(keys[i]) !== '')
          return false;
      }
      return true;
    },
    saveAccount() {
      let item = this.removeCurrency()
      item.maxByDayNoSwipped = 0
      item.maxByMonthNoSwipped = 0
      let loader = this.$loading.show({loader: 'dots'})
      updateAccount(item)
      .then(response => {
        loader.hide()
        if (response.data.statusCode === 200){
          this.$toasted.show('Cuenta actualizada correctamente')
        }else{
          processErrorResponse(response.data, this.$toasted)
        }
      })
      .catch(error => {
        loader.hide()
        this.$emit('error', error)
      })
    },
    clone(obj) {
        if (null == obj || "object" != typeof obj) return obj;
        var copy = obj.constructor();
        for (var attr in obj) {
            if (obj.hasOwnProperty(attr)) copy[attr] = obj[attr];
        }
        return copy;
    },
    removeCurrency() {
      let copyItem = this.clone(this.item)
      copyItem.maxByDay = this.removeComma(copyItem.maxByDay)
      copyItem.maxByMonth = this.removeComma(copyItem.maxByMonth)
      return copyItem
    },
    removeComma(value){
      return parseInt(value.replaceAll(',',''))
    },
    validateField(key) {
      if (this.showErrorMessages) {
        switch (key) {
          case 'name':
            return this.item.name === '' ? 'Ingresa el nombre de la cuenta' : ''
          case 'contactName':
            return this.item.contactName === '' ? 'Ingresa el nombre de contacto' : ''
          case 'contactPhoneNumber':
            return this.item.contactPhoneNumber === '' 
            || this.item.contactPhoneNumber.length < 10 ? 'El número telefónico no es valido' : ''
          case 'contactMobileNumber':
            return this.item.contactMobileNumber === ''
            || this.item.contactMobileNumber.length < 10 ? 'El número de celular no es valido' : ''
          case 'contactMail':
            return !validateMail(this.item.contactMail) ? 'El email no es valido' : ''
          case 'internalKey':
            return isEmptyOrNull(this.item.internalKey) ? "Ingresa la clave interna" : ''
          case 'street':
            return this.item.street === '' ? 'Ingresa la calle' : ''
          case 'extNumber':
            return this.item.extNumber === '' ? 'Ingresa el número del domicilio' : ''
          case 'suburb':
            return this.item.suburb === '' ? 'Ingresa la colonia' : ''
          case 'county':
            return this.item.county === '' ? 'Ingresa el municipio' : ''
          case 'city':
            return this.item.city === '' ? 'Ingresa la ciudad' : ''
          case 'state':
            return this.item.state === '' ? 'Ingresa el estado' : ''
          case 'country':
            return this.item.country === '' ? 'Ingresa el tipo de cliente' : ''
          case 'zipcode':
            return this.item.zipcode === '' ? 'Ingresa el código postal' : ''
          case 'maxByDay':
            return this.item.maxByDay === '' ? 'Ingresa la cantidad maxima por día' : ''
          case 'maxByMonth':
            return this.item.maxByMonth === '' ? 'Ingresa la cantidad maxima por mes' : ''
          case 'businessName':
            return this.item.businessName === '' ? 'Ingresa el nombre del negocio' : ''
          case 'surveyQuestion':
            return this.item.surveyQuestion === '' ? 'Ingresa la pregunta de la encuesta' : ''
          case 'surveyCode':
            return this.item.surveyCode === '' ? 'Ingresa el codigo de la encuesta' : ''
        }
        return ''
      }
    },
    handleError(error) {
      this.$emit('error', error)
    },
    updateNewAccount() {
      this.clean = true
      this.updteTable = true
      this.showCreateAccount = false;
    },
    tableLoaded(){
      this.updteTable = false
    },
    accountUpdated() {
      this.enterpriseId = 0;
      this.showCreateAccount = false;
    },
    newItem() {
      this.showCreateAccount = true;
    },
    prepareUpdateItem(row) {
      this.enterpriseId = row.id;
      this.showCreateAccount = true;
    },
    itemsSelected(event){
      this.showAssignItem = false
      this.item.userPartnerName = event[0].fullName
      this.item.userPartnerId = event[0].id
      this.assignPartner()
    },
    getTextPartnerButton(){
      return isEmptyOrNull(this.item.userPartnerName) ? 'Asignar' : 'Cambiar'
    },
    assignPartner(){
      let loader = this.$loading.show({loader: 'dots'})
      assingAccounts({idUserPartner: this.item.userPartnerId, idAccounts: [this.selectedAccountId]})
      .then(response => {
        loader.hide()
        if (response.data.statusCode === 200) {
          this.$toasted.show('Vendedor vinculado correctamente')
        }else{
          processErrorResponse(response.data, this.$toasted )
        }
      })
      .catch(error => {
        loader.hide()
        this.$emit('error', error)
      })
    },
    partnerSelected(partner){
      this.item.userPartnerName = partner.userPartnerName
      this.item.userPartnerId = partner.userPartnerId
      this.assignPartner()
    }
  },
  async mounted() {
    if (this.$route.params.id > 0) {
      this.selectedAccountId = Number(this.$route.params.id);
      let loader = this.$loading.show({loader: 'dots'})
      getAccountInfo(this.selectedAccountId)
      .then(response => {
        loader.hide()
        if (response.data.statusCode === 200){
          this.showErrorMessages = true
          this.item = response.data.result
          this.currencyAmounts()
        }else{
          processErrorResponse(response.data, this.$toasted)
        }
      })
      .catch(error => {
        loader.hide()
        this.$emit('error', error)
      })
    }
  }
};
</script>
<style>
.nav-pills .nav-item:not(:last-child) {
    padding-right: 0 !important;
}

.disabledTab {
  pointer-events: none;
}
</style>
