<template>
  <div class="card h-100 product-option " >
    <div class="card-body">
    <i :class="icon"></i>
    <h1>{{title}}</h1>
    <hr />
    <p>{{description}}</p>
    </div>
    <div class="card-footer">
      <div v-if="alternateStyle" class="price">
        <div class="front">
          <span class="price">
            {{button}}
          </span>
        </div>
      </div>
      <div v-else class="back">
        <a href="#" @click="$emit('clicked')" class="button">{{button}}</a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'product-card',
  props:{
    icon: String,
    title: String,
    description: String,
    button: String,
    alternateStyle:{
      default: true
    }
  },
}
</script>
<style lang="scss" scoped>
  // variables
  $icon-size: 3rem;
  $icon-color: #10cfc9;
  $main-color: #ff3c00;
  $primary-color: #302277;
  .product-option {
    text-align: center;
    border-radius: .375rem;

    &:hover {
      cursor: pointer;
      box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.3);
      i,
      h1,
      span,
      b {
        color: $icon-color;
      }
      .back {
        a.button {
          transform: translateY(0px) !important;
        }
      }
    }
    i {
      font-size: $icon-size;
      color: $icon-color;
      transition: all 0.3s ease-in-out;
    }
    h1 {
      margin: 10px 0;
      color: $icon-color;
      transition: all 0.3s ease-in-out;
    }
    p {
      color: #999;
      padding: 0 10px;
      line-height: 1.3;
      min-height: 50%;
    }
    .card-footer{
      position: relative;
      padding-top: 25px;
      padding-bottom: 45px;
      .back {
        a.button {
          background: $main-color;
          padding: 15px 20px;
          display: inline-block;
          text-decoration: none;
          color: white;
          position: absolute;
          font-size: 13px;
          top: 10px;
          left: 0;
          right: 0;
          width: 150px;
          margin: auto;
          text-transform: uppercase;
          &:hover {
            background: darken($main-color, 10%);
          }
        }
      }
    }
    .price {
      position: relative;
      .front {
        span.price {
          font-size: 2rem;
          text-transform: uppercase;
          margin-top: 20px;
          display: block;
          font-weight: 700;
          position: relative;
          b {
            position: absolute;
            font-size: 1rem;
            margin-left: 2px;
            font-weight: 600;
          }
        }
      }
      .back {
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s ease-in-out;
        a.button {
          background: $main-color;
          padding: 15px 20px;
          display: inline-block;
          text-decoration: none;
          color: white;
          position: absolute;
          font-size: 13px;
          top: -5px;
          left: 0;
          right: 0;
          width: 150px;
          margin: auto;
          text-transform: uppercase;
          transform: translateY(20px);
          transition: all 0.3s ease-in-out;
          &:hover {
            background: darken($main-color, 10%);
          }
        }
      }
    }
  }
</style>