var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card shadow" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "table-responsive m-2" },
      [
        _c(
          "b-container",
          [
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  {
                    staticClass: "text-center  align-self-center",
                    attrs: { cols: "3" }
                  },
                  [_c("h4", [_vm._v("Banco")])]
                ),
                _c(
                  "b-col",
                  {
                    staticClass: "text-center  align-self-center",
                    attrs: { cols: "3" }
                  },
                  [_c("h4", [_vm._v("Tipo")])]
                ),
                _c("b-col", { staticClass: "text-center" }, [
                  _c("h4", [_vm._v("Tasa")])
                ]),
                _c("b-col", { staticClass: "text-center" }, [
                  _c("h4", [_vm._v("Sobre Tasa")])
                ])
              ],
              1
            ),
            _vm._l(_vm.fees, function(fee) {
              return _c(
                "b-row",
                { key: fee.id, staticClass: "mt-3" },
                [
                  _c(
                    "b-col",
                    { staticClass: "text-center", attrs: { cols: "3" } },
                    [_vm._v("\n          " + _vm._s(fee.bank) + "\n        ")]
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass: "text-center  align-self-center",
                      attrs: { cols: "3" }
                    },
                    [
                      _c("label", { attrs: { lass: "form-control-label" } }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(fee.keyDesc) +
                            "\n          "
                        )
                      ])
                    ]
                  ),
                  _c(
                    "b-col",
                    [
                      _c("base-input", {
                        staticClass: "align-self-center",
                        staticStyle: { "margin-bottom": "0px" },
                        attrs: {
                          "input-classes": "form-control-alternative",
                          validation: "number",
                          maxlength: 5
                        },
                        model: {
                          value: fee.baseFee,
                          callback: function($$v) {
                            _vm.$set(fee, "baseFee", $$v)
                          },
                          expression: "fee.baseFee"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    [
                      _c("base-input", {
                        staticClass: "align-self-center",
                        staticStyle: { "margin-bottom": "0px" },
                        attrs: {
                          "input-classes": "form-control-alternative",
                          validation: "number",
                          maxlength: 5
                        },
                        model: {
                          value: fee.overFee,
                          callback: function($$v) {
                            _vm.$set(fee, "overFee", $$v)
                          },
                          expression: "fee.overFee"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            })
          ],
          2
        ),
        _c("div", { staticClass: "bg-white border-0" }, [
          _c("div", { staticClass: "row align-items-center" }, [
            _c(
              "div",
              { staticClass: "col-12 text-right" },
              [
                _c(
                  "base-button",
                  {
                    staticClass: "my-4 ml-3 mr-3",
                    attrs: { type: "primary" },
                    on: { click: _vm.saveFees }
                  },
                  [_vm._v("Guardar")]
                )
              ],
              1
            )
          ])
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header border-0" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col mt-2 align-self-center" }, [
          _c("h3", { staticClass: "mb-0" }, [
            _vm._v("\n          Comisiones\n        ")
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }