var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.idEnterprise
        ? _c("new-branch-modal", {
            attrs: {
              showCreateAccount: _vm.showCreateAccount,
              ownerId: _vm.idEnterprise,
              itemId: _vm.idEnterprise,
              clean: _vm.clean,
              type: _vm.type
            },
            on: {
              close: _vm.closeAccountModal,
              error: _vm.handleError,
              "branch-created": _vm.branchCreated,
              cleaned: function($event) {
                _vm.clean = false
              },
              "Account-updated": _vm.accountUpdated
            }
          })
        : _vm._e(),
      _c("new-security-modal", {
        attrs: {
          showSecurityModal: _vm.showSecurityModal,
          ownerId: _vm.ownerIDI
        },
        on: {
          close: _vm.closeSecurityModal,
          error: _vm.handleError,
          "security-updated": _vm.closeSecurityModal
        }
      }),
      _c(
        "base-header",
        {
          staticClass: "pb-6 pb-8 pt-5 pt-md-8",
          attrs: { type: "gradient-primary" }
        },
        [
          _vm.isUserAccount
            ? _c("card-stats", { attrs: { period: _vm.period } })
            : _vm._e()
        ],
        1
      ),
      _c("div", { staticClass: "container-fluid mt--7" }, [
        _c("div", { staticClass: "row mt-5" }, [
          _c(
            "div",
            { staticClass: "col-xl-12" },
            [
              _c("branches-table-v-2", {
                attrs: {
                  idEnterprise: _vm.idEnterprise,
                  updateTable: _vm.updateTable,
                  type: _vm.type
                },
                on: {
                  "table-loaded": _vm.tableLoaded,
                  "security-item": _vm.prepareSecurityItem,
                  "new-item": _vm.newItem,
                  error: _vm.handleError,
                  "update-item": _vm.prepareUpdateItem
                }
              })
            ],
            1
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }