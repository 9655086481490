<template >
  <div>
    <assign-item-modal
      :showAssignItem="showAssignItem"
      :type="'partner-account'"
      :isMultipleSelection="false"
      v-on:close="showAssignItem=false"
      v-on:selectedItems="itemsSelected"
      v-on:error="handleError"
    ></assign-item-modal>
    <b-container style="padding: 0px"  >
      <b-row>
        <b-col cols="9">
          <label class="form-control-label">
            Vendedor
          </label>
          <input
            alternative
            style="cursor: pointer; background-color: white" 
            class="form-control" 
            v-model="item.userPartnerName"
            readonly="true"
            required>
        </b-col>
        <b-col cols="3"  class="align-self-center text-right">
          <base-button type="primary" class="my-4 ml-3 mr-3"  @click="showAssignItem = true" >{{getTextPartnerButton()}}</base-button>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import "vue-select/dist/vue-select.css";
import { isEmptyOrNull } from '../utils/generalMethods';
import AssignItemModal from '../views/Modals/AssignItemModal.vue';
export default {
  components: { AssignItemModal },
  name: 'partner-branch-selector',
  props: {
    partnerSelected: {},
    errorPartner: String,
  },
  data() {
    return {
      showAssignItem: false,
      item: {
        userPartnerName: '',
        userPartnerId: ''
      }
    }
  },
  watch:{
    partnerSelected: {
      handler(newItem){
        this.item.userPartnerName = newItem.userPartnerName
        this.item.userPartnerId = newItem.userPartnerId
      }
    }
  },
  methods:{
    handleError(error){
      this.$emit(error)
    },
    getTextPartnerButton(){
      return isEmptyOrNull(this.item.userPartnerName) ? 'Asignar' : 'Cambiar'
    },
    itemsSelected(event){
      this.showAssignItem = false
      this.item.userPartnerName = event[0].fullName
      this.item.userPartnerId = event[0].id
      this.$emit('partner-selected', this.item)
    }
  }
};
</script>
<style>
  .big-icon {
    font-size: 2.3em !important;
}
</style>
